.pageContainer {
  position: relative;
  z-index: 10;
}

@mixin desktop {
  .pageContainer {
    max-width: 1440px;
    margin: 0 auto;
    box-shadow: 0 0 0.6rem 0 rgb(0 0 0 / 20%);
  }
}

.formContainer {
  position: relative;
}

.formInnerContainer {
  width: 100%;
  padding-right: 0.625rem;
  padding-left: 0.625rem;
  margin-right: auto;
  margin-left: auto;
}

.donationFormBackground {
  background-color: var(--betterplace-color-bg-secondary);
}

.formWrapper {
  background-color: white;
  position: relative;
  inline-size: 100%;
  padding-inline: 10px;
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -10px;
  margin-left: -10px;
  justify-content: center;
}

@mixin desktop {
  .formInnerContainer {
    max-inline-size: 40rem;
  }

  .transparentHeaderSpace {
    padding-top: 65px;
  }

  .formWrapper {
    flex: 0 0 1;
    margin-block: 60px 100px;
  }
}

@mixin tablet {
  .formWrapper {
    margin-block: 0;
  }

  .transparentHeaderSpace {
    padding-top: 0;
  }
}

.headerLogo {
  grid-area: logo;
  position: relative;
}

@mixin mobile {
  .headerLogo {
    padding-inline: var(--betterplace-spacing-200);
    align-self: center;
  }
}

@mixin mobile {
  .headerLogo img {
    height: var(--betterplace-sizing-400);
    width: auto;
  }
}

@mixin desktop {
  .headerLogo img {
    height: var(--betterplace-sizing-500);
    width: auto;
  }
}

.headerLogo .logoCompact {
  display: none;
}

.headerClaim {
  grid-area: claim;
  color: var(--betterplace-color-bg-neutral);
  white-space: nowrap;
  position: absolute;
  left: 10px;
  top: 25px;
  font: var(--betterplace-typography-label-xs-secondary);
  text-overflow: ellipsis;
  overflow: hidden;
}

@media only screen and (width <= 1250px) {
  .headerClaim {
    display: none;
  }
}

.clientHeaderContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.projectsWirwunderHeader {
  background: #ff0000;
}

.projectsWirwunderHeader .logoWirwunder,
.projectsNussbaumHeader .logoGemeinsamHelfen {
  margin-top: 0.5rem;
  margin-bottom: 0.75rem;
}

@mixin mobile {
  .projectsWirwunderHeader .logoWirwunder,
  .projectsNussbaumHeader .logoGemeinsamHelfen {
    margin-left: 10px;
    max-height: 40px;
  }
}

@mixin desktop {
  .projectsWirwunderHeader .logoWirwunder {
    margin-left: 51px;
    height: 50px;
  }

  .projectsWirwunderHeader .logoSparkasse {
    margin-right: 26px;
    height: 70px;
  }
}

@mixin mobile {
  .projectsWirwunderHeader .logoSparkasse {
    height: 60px;
    max-width: 210px;
  }
}

.projectsNussbaumHeader {
  background: #662465;
}

@mixin desktop {
  .projectsNussbaumHeader .logoGemeinsamHelfen {
    margin-left: 51px;
    height: 140px;
    width: 254px;
  }

  .projectsNussbaumHeader .logoNussbaum {
    margin-right: 51px;
    height: 140px;
    width: 244px;
  }
}

@mixin mobile {
  .projectsNussbaumHeader .logoNussbaum {
    margin-right: 10px;
    height: 60px;
    max-width: 210px;
  }
}
